import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/architecting-skpr-configuration-system",
  "date": "24 February 2021",
  "title": "Architecting the Skpr Configuration System",
  "summary": "This blog post covers the considerations which went into the design and development of the Skpr configuration system.",
  "author": "Nick Schuch",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "config"
  }, {
    "name": "developer experience"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Skpr provides development teams with a robust system for configuring their applications.`}</p>
    <p>{`This blog post covers the considerations that went into the design and development of that system.`}</p>
    <h3>{`What Is Configuration?`}</h3>
    <p>{`Configuration can be a loaded term for infrastructure, we define configuration as `}<em parentName="p">{`the values used to modify the behaviour of an application.`}</em></p>
    <p>{`These `}<em parentName="p">{`behaviours`}</em>{` are typical:`}</p>
    <ul>
      <li parentName="ul">{`Connecting to services eg. database, search, external api etc`}</li>
      <li parentName="ul">{`Flags to enable/disable features`}</li>
      <li parentName="ul">{`Provide context to an application for where it is running. eg. dev/stg/prod`}</li>
    </ul>
    <h3>{`Existing Patterns`}</h3>
    <p>{`Before building our configuration system we evaluated the cloud hosting ecosystem as a whole to find existing patterns. `}</p>
    <p>{`In this section, we cover two common patterns we've observed.`}</p>
    <h4>{`1) Include Files`}</h4>
    <p>{`The hosting platform provides a file that is language/application type sensitive that can be included in the application's codebase eg. Acquia provides a `}<em parentName="p">{`settings.php`}</em>{` which can be included by a Drupal application.`}</p>
    <p>{`This include file only configures the bare minimum to avoid conflicts with the application. This includes:`}</p>
    <ul>
      <li parentName="ul">{`Database connection`}</li>
      <li parentName="ul">{`Filesystem locations`}</li>
    </ul>
    <p>{`If you have deployed an application on Acquia or Pantheon than you have most likely had to use this pattern before.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`# Inheriting the platform's configuration.
require '/var/www/my-application/settings.php';

# Setting my own custom configuration.
$settings['googleanalytics_account'] = 'UA-XXXXXXXX-X';
`}</code></pre>
    <p>{`The example above demonstrates how this pattern can lead to a simple configuration solution. The downside is that `}<strong parentName="p">{`you need to manage any custom configuration yourself`}</strong>{`.`}</p>
    <p>{`The typical workaround we have seen is for teams to hardcode these configurations in the application and commit them to the code base.`}</p>
    <p>{`This example from `}<a parentName="p" {...{
        "href": "https://pantheon.io/docs/settings-php#how-can-i-write-logic-based-on-the-pantheon-server-environment"
      }}>{`Pantheon's documentation`}</a>{` demonstrates how a development team might set a custom configuration.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`if (PANTHEON_ENVIRONMENT == 'dev') {
  // Google Analytics.
  $conf['googleanalytics_account'] = 'UA-XXXXXXXX-X';
}
else if (PANTHEON_ENVIRONMENT == 'test') {
  // Google Analytics.
  $conf['googleanalytics_account'] = 'UA-XXXXXXXX-Y';
}
else if (PANTHEON_ENVIRONMENT == 'live') {
  // Google Analytics.
  $conf['googleanalytics_account'] = 'UA-XXXXXXXX-Z';
}
`}</code></pre>
    <p>{`This leads to security and development workflow issues as: `}</p>
    <ul>
      <li parentName="ul">{`Credentials are now committed to the codebase and accessible on developer workstations, code hosting services, CI/CD pipelines, etc.`}</li>
      <li parentName="ul">{`Configuration changes need to be deployed eg. an API Token update`}</li>
    </ul>
    <p>{`In summary, this approach works great until custom configuration is required. Development teams then need to make compromises on security and workflow to have a custom configuration set.`}</p>
    <h4>{`2) Environment Variables`}</h4>
    <p>{`Environment variables are a popular approach for configuring applications and are supported by a wide range of hosting providers. They are also very easy to integrate into an application's codebase.`}</p>
    <p>{`For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$conf['googleanalytics_account'] = getenv('GOOGLEANALYTICS_ACCOUNT');
`}</code></pre>
    <p>{`Acquia, Lagoon and Pantheon all have support for environment variables. However, they all have varying workflows to support them:`}</p>
    <ul>
      <li parentName="ul">{`Acquia provides a web interface`}</li>
      <li parentName="ul">{`Lagoon and Pantheon provide this functionality via lower level tooling eg. command-line interface, API interface and codebase "commit" options`}</li>
    </ul>
    <p>{`While environment variables are convenient, they do come with the downside that `}<strong parentName="p">{`the application must be restarted or redeployed for an update to take effect`}</strong>{`. While this should not lead to downtime, it could under certain conditions.`}</p>
    <p>{`A potential condition would be setting the configuration 10 times requiring 10 restarts or redeployments on the platform. While this shouldn't be a problem with rolling updates and robust traffic routing configurations, we were still concerned that this `}<em parentName="p">{`could`}</em>{` happen.`}</p>
    <p>{`One method to combat the restart/redeploy downside is to set the configuration (via the web, command line or API interface) and wait for the next deployment for the configuration update to take effect. While this will solve the issue, it creates a new one - `}<strong parentName="p">{`predictability`}</strong>{`.`}</p>
    <p>{`We found that coupling configuration updates to deployments led to confusion eg. `}</p>
    <ul>
      <li parentName="ul">{`Is the configuration already applied? `}</li>
      <li parentName="ul">{`Are we still waiting for a deployment? `}</li>
      <li parentName="ul">{`Are there other configuration values which have been set that are waiting for a deployment?`}</li>
    </ul>
    <p>{`With all of the above in mind, we ultimately chose not to use environment variables for our configuration system.`}</p>
    <h3>{`Developer Experience Matters`}</h3>
    <p>{`While all the cloud providers mentioned in this blog post provide a solution, we felt they lacked a great developer experience.`}</p>
    <p>{`Developers deserve a simple set of tools to interact with, and certainty when setting their configuration.`}</p>
    <h3>{`Skpr's Solution`}</h3>
    <p>{`Taking the above patterns into consideration, we have built a system with a focus on:`}</p>
    <ul>
      <li parentName="ul">{`Avoiding application restarts/redeployments to apply configuration`}</li>
      <li parentName="ul">{`Improved Developer Experience`}</li>
    </ul>
    <h4>{`Developer Experience`}</h4>
    <p>{`Skpr provides a simple command-line interface for developers to set, list and delete the configuration.`}</p>
    <p><strong parentName="p">{`Setting Configuration`}</strong></p>
    <p>{`Configuration can be created or updated using the `}<em parentName="p">{`set`}</em>{` command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Setting a non-secret config.
$ skpr config set prod api.username nickschuch

# Setting a secret.
$ skpr config set prod --secret api.password xxxyyyzzz
`}</code></pre>
    <p><strong parentName="p">{`Listing Configuration`}</strong></p>
    <p>{`Developers can view which configuration is set for each environment, this example demonstrates which config is set for production.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ skpr config list prod
 ───────────────────────── ───────────────────
  KEY (26)                  VALUE
 ───────────────────────── ───────────────────
  api.username              nickschuch
  api.password              [secret]
`}</code></pre>
    <p><strong parentName="p">{`Delete Configuration`}</strong></p>
    <p>{`Configuration can also be deleted using the `}<em parentName="p">{`delete`}</em>{` command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ skpr config delete prod api.username
$ skpr config delete prod api.password 
`}</code></pre>
    <h4>{`How Do We Avoid Restarts?`}</h4>
    <p>{`Skpr provides applications with a JSON file containing all the configuration provided by both the platform and the developer.`}</p>
    <p>{`We chose a JSON file because:`}</p>
    <ul>
      <li parentName="ul">{`It's language agnostic`}</li>
      <li parentName="ul">{`Can be automatically updated without restarts/redeployments`}</li>
    </ul>
    <p>{`Below is an example of the JSON file which can be loaded by an application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ cat /etc/skpr/data/config.json | jq
{
  "api.username": "nickschuch",
  "api.password": "xxxyyyzzz"
}
`}</code></pre>
    <p>{`The file is typically much larger given it contains, by default, connection details for the following services:`}</p>
    <ul>
      <li parentName="ul">{`MySQL`}</li>
      <li parentName="ul">{`Solr/Elasticsearch`}</li>
      <li parentName="ul">{`ClamAV`}</li>
      <li parentName="ul">{`SMTP`}</li>
    </ul>
    <p>{`The Skpr platform team maintain a set of packages (PHP, Node, Ruby and Go) to smooth out the integration between the Skpr configuration system and the application.`}</p>
    <h4>{`Integrating It Into Your Site`}</h4>
    <p>{`Below is an example using the PHP package with a Drupal application.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`use Skpr\\SkprConfig;

$skpr = SkprConfig::create()->load();

# Simple example.
$conf['api_username'] = $skpr->get('api.username');
$conf['api_password'] = $skpr->get('api.password');

# Advanced example (with fallback).
$databases['default']['default'] = [
  'driver'   => 'mysql',
  'database' => $skpr->get('mysql.default.database') ?: 'local',
  'username' => $skpr->get('mysql.default.username') ?: 'local',
  'password' => $skpr->get('mysql.default.password') ?: 'local',
  'host'     => $skpr->get('mysql.default.hostname') ?: '127.0.0.1',
  'port'     => $skpr->get('mysql.default.port') ?: '3306',
];
`}</code></pre>
    <h4>{`Extending This Solution`}</h4>
    <p>{`This solution isn't just for setting API keys etc. It can also be used to provide additional context about the environment to which the application is deployed.`}</p>
    <p><strong parentName="p">{`Hostnames`}</strong></p>
    <p>{`Skpr also exposes a list of hostnames as a JSON file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ cat /etc/skpr/data/hostnames.json | jq
[
  "dev.example.cluster.skpr.dev"
]
`}</code></pre>
    <p>{`This allows for applications like Drupal 8+ to automatically configure its `}<em parentName="p">{`trusted_host_patterns`}</em>{` setting based on the environment.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`foreach ($skpr->hostNames() as $hostname) {
  $settings['trusted_host_patterns'][] = '^' . preg_quote($hostname) . '$';
}
`}</code></pre>
    <p><strong parentName="p">{`IP Ranges`}</strong></p>
    <p>{`Environments also have access to a list of internal IP ranges which can be useful when determining external addresses eg. the client IP address.`}</p>
    <p>{`The following example demonstrates how easy it is to integrate with Drupal.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`$settings['reverse_proxy_addresses'] = $skpr->ipRanges();
`}</code></pre>
    <p><strong parentName="p">{`Environment Variables`}</strong></p>
    <p>{`The PHP library also transforms Skpr configuration into environment variables for each request using `}<a parentName="p" {...{
        "href": "https://www.php.net/manual/en/function.putenv.php"
      }}>{`putenv`}</a>{`.`}</p>
    <p>{`This allows for a frictionless experience when migrating applications with environment variables configuration to the Skpr platform.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`# Using the library.
$conf['api_username'] = $skpr->get('api.username');
$conf['api_password'] = $skpr->get('api.password');

# Using environment variables.
$conf['api_username'] = getenv('API_USERNAME');
$conf['api_password'] = getenv('API_PASSWORD');
`}</code></pre>
    <h3>{`Resources`}</h3>
    <p>{`The following resources were used during the writing of this blog post.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.skpr.io/config/"
        }}>{`Skpr Docs - Config`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pantheon.io/docs/settings-php#how-can-i-write-logic-based-on-the-pantheon-server-environment"
        }}>{`Pantheon Docs - How can I write logic based on the Pantheon server environment?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.platform.sh/bestpractices/environment-build.html"
        }}>{`Platform.sh Docs - Environment variables`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.acquia.com/cloud-platform/develop/env-variable/"
        }}>{`Acquia Docs - Environment variables`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.acquia.com/cloud-platform/manage/variables/"
        }}>{`Acquia Docs - Variables`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/amazeeio/lagoon/issues/1728"
        }}>{`Amazee.io Docs - Environment variables`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      